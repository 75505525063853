<script lang="ts">
	export let classes = '';
</script>

<div class="flex items-center justify-center gap-x-2 {classes}">
	<iconify-icon
		icon="logos:amex-digital"
		height="20"
		class=" overflow-hidden rounded-sm bg-[#016FCE] pl-2"
	></iconify-icon>
	<iconify-icon icon="logos:mastercard" height="12" class="rounded-sm bg-surface-50 px-2 py-1"
	></iconify-icon>
	<iconify-icon icon="logos:visa" height="12" class="rounded-sm bg-surface-50 p-1"></iconify-icon>
	<iconify-icon icon="logos:google-pay" height="12" class="rounded-sm bg-surface-50 p-1"
	></iconify-icon>
</div>
